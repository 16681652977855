import React, { useState } from 'react';
import { PageProps, Link } from "gatsby";

import { FormspreeProvider } from '@formspree/react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import ContactForm from '../components/contactForm.js';

import Layout from "../components/layout";
import SEO from "../components/seo";


const ContactPage = (props: PageProps) => {
    return (
        <Layout>
            <SEO title="Contact" />

            <div className="content_container">
                <Typography className="page_header" component="h1" variant="h3" paragraph>
                    Contact
                </Typography>

                <Typography component="p" paragraph>
                    Interested in capturing a special location on canvas? Complete the form below and we can discuss the creation of your special memory.
                </Typography>

                <Grid container spacing={2}>
                <FormspreeProvider project="1571123722394271344">
                    <ContactForm />
                </FormspreeProvider>

                    
                </Grid>
            </div>
        </Layout>
    );
}
  
export default ContactPage;