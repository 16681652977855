import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import { useForm } from '@formspree/react';
import DOMPurify from 'dompurify';
import validator from 'validator';

const styles = {
    paper: {
        display: 'flex',
        flexDirection: 'column'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: 15,
    },
    submit: {
        marginTop: 15,
        width: '40%'
    },
}

const ContactForm = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [state, handleSubmit] = useForm('contactForm', {
        data: {
            // These functions will be evaluated at submission time
            firstName: function() {
                return DOMPurify.sanitize(firstName, {USE_PROFILES: {html: false}})
            },
            lastName: function() {
                return DOMPurify.sanitize(lastName, {USE_PROFILES: {html: false}})
            },
            email: function() {
                return DOMPurify.sanitize(email, {USE_PROFILES: {html: false}})
            },
            _subject: function() {
                return DOMPurify.sanitize(subject, {USE_PROFILES: {html: false}})
            },
            message: function() {
                return DOMPurify.sanitize(message, {USE_PROFILES: {html: false}})
            }
        }
    })

    useEffect(() => {
        if (!state.submitting && state.succeeded) {
            handleSubmitSucceed();
        }

        if (!state.submitting && state.errors.length) {
            handleSubmitError();
        }
    }, [state])

    const handleTextInputChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        switch(name) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'subject':
                setSubject(value);
                break;
            case 'message':
                setMessage(value);
                break;
            default:
                break;
        }
    }

    const handleResetState = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setSubject('');
        setMessage('');
    }

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    }

    const isSubmitDisabled = () => {
        let submitDisabled = false;

        if (!firstName || !lastName || !email || !subject || !message) {
            submitDisabled = true;
        }

        if (!validator.isEmail(email)) {
            submitDisabled = true;
        }

        return submitDisabled;
    }

    const handleSubmitSucceed = () => {
        setSnackbarType('success');
        setSnackbarOpen(true);
        handleResetState();

        if (typeof window !== 'undefined') {
            setTimeout(() => {
                window.location.reload();
            }, 4000);
        }
    }
    
    const handleSubmitError = () => {
        setSnackbarType('error');
        setSnackbarOpen(true);
    }

    const renderSnackbar = () => {
        let snackbarMessage;
        let snackbarStyle;

        if (snackbarType === 'success') {
            snackbarMessage = "Your message was sent successfully. We will get back to you shortly!";
            snackbarStyle = {backgroundColor: '#4caf50', color: '#fff'};
        } else {
            snackbarMessage = "There was an error with submitting your email request. Please try again.";
            snackbarStyle = {backgroundColor: '#f44336', color: '#fff'};
        }

        return (
            <Snackbar 
                open={snackbarOpen} 
                autoHideDuration={6000} 
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={handleCloseSnackbar}
            >
                <SnackbarContent
                    message={snackbarMessage}
                    style={snackbarStyle}            
                />
            </Snackbar>
        )
    }

    return (
        <div style={{width: '100%'}}>
            {renderSnackbar()}

            <form 
                style={styles.form} 
                noValidate 
                onSubmit={handleSubmit}
                action="https://formspree.io/p/1571123722394271344/f/contactForm" 
                method="POST"
            >
                <Grid container direction="column" justify="center" spacing={2} style={{margin: 0, width: 'inherit'}}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            name="firstName"
                            value={firstName}
                            onChange={handleTextInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            value={lastName}
                            onChange={handleTextInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            type="email"
                            value={email}
                            onChange={handleTextInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            id="subject"
                            label="Subject"
                            name="subject"
                            value={subject}
                            onChange={handleTextInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            multiline
                            rows={4}
                            id="message"
                            label="Message"
                            name="message"
                            value={message}
                            onChange={handleTextInputChange}
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={styles.submit}
                            disabled={isSubmitDisabled() || state.submitting}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default ContactForm;